$shadow-color: rgba(0, 0, 0, 0.15) 0px 4px 12px 0px;
$white-color: #fff;

.productCard {
  box-shadow: $shadow-color;
  width: 31%;
  height: 100%;
  //   min-width: 10rem;
  background-color: $white-color;
  margin: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  & a {
    text-align: center;
    text-decoration: none;
    background-color: $white-color;
    padding: 0rem 0rem 0.5rem 0rem;
    font-size: 18px;
  }
  .productCardDetails {
    .title {
      color: #000;
      font-size: 18px;
      font-weight: bold;
    }
    .mrp {
      color: #999;
      font-style: italic;
      text-decoration: line-through;
      font-size: 16px;
    }
    .price {
    }
    .discount {
      color: rgb(30, 139, 30);
      font-size: 16px;
      padding-left: 0.25rem;
    }
  }
}
