$icon-color: #6d4211;
$link-color: #6d4211;
$title-color: #666;
$text-color: #111;
$white-color: #fff;
$border-color: #ccc;
$shadow-color: rgba(0, 0, 0, 0.15) 0px 4px 12px 0px;
// add  link color same as icon color
$link-hover-color: #3bb9ae;
$background: #eddcc4;

body {
  width: 100%;
  margin: 0px;
  font-family: Arial, Helvetica, sans-serif;
  // background-color: $background;
  background-image: url("assets/background.jpg");
  background-repeat: no-repeat;
  background-color: $white-color;
  img {
    width: 100%;
  }
}

.search {
  min-width: 1rem;
  .searchInput {
    background: transparent;
    border-radius: 0.5rem;
    border: 2px solid;
    width: 10rem;
    height: 1.5rem;
    position: absolute;
    margin: 0rem 1rem;
  }
}

.menu {
  margin: 1rem 2rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $icon-color;
  .menuItem {
    margin: 0rem 1rem;
    a {
      color: $link-color;
      text-decoration: none;
    }
    a:hover {
      color: $link-hover-color;
    }
  }
}

.footer {
  margin: 1rem 2rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $icon-color;
  .footerItem {
    margin: 0rem 1rem;
    a {
      color: $link-color;
      text-decoration: none;
    }
    a:hover {
      color: $link-hover-color;
    }
  }
}

.footerIcons {
  display: flex;
  justify-content: center;
  color: $icon-color;
  span {
    padding: 0rem 1rem;
  }
}

.copyright {
  padding: 1rem;
  display: flex;
  justify-content: center;
  font-size: 10px;
  color: $icon-color;
  a {
    text-decoration: none;
    color: $icon-color;
  }
}

.homeCarousel {
  margin-bottom: 1rem;
}

.offers,
.category {
  .offersHeader,
  .categoryHeader {
    margin-top: 4rem;
    display: flex;
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
    color: $title-color;
  }

  .offersCardsContainer {
    display: flex;
    padding: 1rem 0.5rem;
    justify-content: space-between;
    overflow-x: auto;
    .offerCard {
      width: 23%;
      height: 100%;
      min-width: 10rem;
      background-color: $white-color;
      margin: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: 1px solid $link-hover-color;
      outline-offset: -10px;
      border: 1px solid $border-color;
      & a {
        background-color: $background;
      }
    }
  }

  .categoryCardsContainer {
    display: flex;
    padding: 1rem 0.5rem 1rem 0rem;
    justify-content: space-between;
  }
}

.categoryCard {
  box-shadow: $shadow-color;
  width: 31%;
  height: 100%;
  //   min-width: 10rem;
  background-color: $white-color;
  margin: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  & a {
    text-align: center;
    text-decoration: none;
    background-color: $white-color;
    padding: 0rem 0rem 0.5rem 0rem;
    font-size: 18px;
  }
  .categoryCardTitle {
  }
}

.admin {
  display: flex;
  height: 100vh;
  .loginPanel {
    display: flex;
    flex-direction: column;
    width: 50%;
    min-width: 18rem;
    justify-content: center;
    margin: auto;
    align-items: center;
    .adminHeader {
      text-align: center;
      .logo {
        width: 8rem;
      }
    }

    .username,
    .password {
      width: 98%;
      background-color: $white-color;
      border: 1px solid $border-color;
      margin-top: 1rem;
      box-shadow: $shadow-color;
      border-radius: 10px;
      padding: 0.5rem;
      font-size: 16px;
    }
  }
}

.activeButton {
  width: 100%;
  background-color: $link-color;
  color: $background;
  border: 1px solid $border-color;
  margin-top: 1rem;
  box-shadow: $shadow-color;
  border-radius: 10px;
  padding: 0.5rem;
  font-size: 16px;
}

.neutralButton {
  width: 100%;
  background-color: transparent;
  color: $link-color;
  border: 1px solid $border-color;
  margin-top: 1rem;
  box-shadow: $shadow-color;
  border-radius: 10px;
  padding: 0.5rem;
  font-size: 16px;
}

.addProductForm {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  input,
  select {
    width: 98%;
    background-color: $white-color;
    border: 1px solid $border-color;
    margin-top: 1rem;
    box-shadow: $shadow-color;
    border-radius: 10px;
    padding: 0.5rem;
    font-size: 16px;
  }
}
