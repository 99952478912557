$icon-color: #6d4211;

.comingSoonBody {
    background-image: url("../assets/background.jpg");
    background-color: #fff;
    width: 100%;
    height: 100vh;
    align-content: center;
    margin: auto;
    text-align: center;
    .logo {
      width: 8rem;
    }
    .header {
      font-size: 2.5rem;
      font-weight: bold;
      padding: 1rem;
      justify-content: center;
      background-color: transparent;
    }
    .subHeader {
      font-size: 20px;
      padding: 1rem;
    }
    .instagram {
      font-size: 12px;
      .icon {
        font-size: 32px;
        color: $icon-color;
      }
    }
  }